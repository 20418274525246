<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav d-xl-none">
      <b-row>
        <b-col class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <li class="nav-item">
              <b-link class="nav-link" @click="toggleVerticalMenuActive">
                <feather-icon icon="MenuIcon" size="21" />
              </b-link>
            </li>

            <dark-Toggler />
            <b-col v-if="priceLoading" cols="12" class="text-center">
              <b-spinner variant="primary" />
            </b-col>
            <b-col
              v-else
              class="
                d-flex
                justify-content-between
                flex-column
                daily-top-padding
              "
            >
              <div class="d-flex justify-content-start">
                <b-avatar size="sm" :src="appLogoImage" />
                <span> {{ convertPrice() }} $</span>
              </div>
            </b-col>
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <b-img :src="appLogoImage" alt="logo" />
              </span>
            </b-link>
          </div>
        </b-col>
      </b-row>
    </ul>
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
      <div class="ml-2">
        <span class="pointer px-1 span_class" @click="linked('scanned-results')"
          ><small class="font-weight-bold"><b>SCANNED RESULTS</b> </small></span
        >
      </div>
      <div class="ml-1">
        <span class="pointer px-1 span_class token_link" @click="goToMatador()"
          ><small class="font-weight-bold"><b>MATADOR TOKEN</b> </small></span
        >
      </div>
      <div v-if="priceLoading" cols="12" class="text-center">
        <b-spinner variant="primary" />
      </div>

      <div v-else>
        <span class="pointer px-1 span_class token_link">
          <b-avatar size="sm" :src="appLogoImage" />
          <span> {{ convertPrice() }} $</span>
        </span>
      </div>
    </div>
    <div :class="!is_mobilesize ? 'pr-5' : 'pr-2 pb-2 pl-2'">
      <v-google-translate
        :languages="!is_mobilesize ? countries : countries_mobile"
      />
    </div>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import { BLink, BImg, BAvatar, BRow, BCol, BSpinner } from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Ripple from "vue-ripple-directive";
import { mixinList } from "@/mixins/mixinList";
import { mapState } from "vuex";

export default {
  mixins: [mixinList],
  data() {
    return {
      countries_mobile: [
        {
          code: "en",
          name: "",
          cname: "英语",
          ename: "",
        },
        {
          code: "ru",
          name: "",
          cname: "俄语",
          ename: "",
        },
        {
          code: "zh-CN",
          name: "",
          cname: "中文 (简体)",
          ename: "",
        },
        {
          code: "ja",
          name: "",
          cname: "日语",
          ename: "",
        },
        {
          code: "ko",
          name: "",
          cname: "韩语",
          ename: "",
        },
        {
          code: "es",
          name: "",
          cname: "西班牙语",
          ename: "",
        },
        {
          code: "ar",
          name: "",
          cname: "阿拉伯语",
          ename: "",
        },
        {
          code: "pt",
          name: "",
          cname: "葡萄牙语",
          ename: "",
        },
        {
          code: "fr",
          name: "",
          cname: "法语",
          ename: "",
        },
      ],
      countries: [
        {
          code: "en",
          name: "English",
          cname: "英语",
          ename: "English",
        },
        {
          code: "ru",
          name: "Русский",
          cname: "俄语",
          ename: "Russian",
        },
        {
          code: "zh-CN",
          name: "中国人",
          cname: "中文 (简体)",
          ename: "Chinese",
        },
        {
          code: "ja",
          name: "にほんご",
          cname: "日语",
          ename: "Japanese",
        },
        {
          code: "ko",
          name: "한국어",
          cname: "韩语",
          ename: "Korean",
        },
        {
          code: "es",
          name: "Español",
          cname: "西班牙语",
          ename: "Spanish",
        },
        {
          code: "ar",
          name: "العربية",
          cname: "阿拉伯语",
          ename: "Arabic",
        },
        {
          code: "pt",
          name: "Português",
          cname: "葡萄牙语",
          ename: "Portuguese",
        },
        {
          code: "fr",
          name: "Français",
          cname: "法语",
          ename: "French",
        },
      ],
    };
  },
  components: {
    BImg,
    BLink,
    BAvatar,
    DarkToggler,
    BRow,
    BCol,
    BSpinner,
  },
  setup() {
    // App Name
    const { appLogoImage, appName } = $themeConfig.app;
    return {
      appLogoImage,
      appName,
    };
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      price: (state) =>
        state.scan.parent_token_meta_data
          ? state.scan.parent_token_meta_data.derivedUSD
          : null,
    }),
    priceLoading() {
      return this.$store.state.loaders.priceLoading;
    },
  },
  methods: {
    linked: function (e) {
      this.$router.push({ path: "/" + e });
    },
    goToMatador() {
      window.open("https://www.matadortoken.com");
    },
    convertPrice() {
      const price = Number(this.price);
      return price ? price.toFixed(10) : "";
    },
  },
  mounted() {},
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
img {
  border-style: none;
  width: 29px;
  margin-top: -30px;
  margin-left: 20px;
}
.daily-top-padding {
  margin-top: 10px;
}
</style>
