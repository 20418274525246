export default [
  {
    title: 'Home',
    route: 'scan',
    icon: 'HomeIcon',
  },
  {
    title: 'Scanned results',
    route: 'scanned-results',
    icon: 'TrendingUpIcon',
  }
]
